import {supabase} from "../../utils/supabaseClient";
import {definitions} from "../../../types/supabase";

export type Profile = definitions['profiles'];

export async function getProfile(callback: any) {
    const user = supabase.auth.user()

    if (!user) return null;

    let profile = null;

    let {data, error, status} = await getSupabaseProfile(user.id)

    if (!data || data.length === 0) {
        data = await createProfile()
    }

    profile = !data ? null : data.pop()

    if (error && status !== 405) {
        throw error
    }

    // use data on callback
    callback(profile)
}

export async function updateProfile(updates: any) {
    const user = supabase.auth.user()!

    updates.id = user.id
    updates.updated_at = new Date()

    let {data, error} = await supabase.from('profiles').upsert(updates)

    if (error) {
        throw error
    }
}


async function createProfile() {
    const user = supabase.auth.user()!

    const blankProfile = {
        id: user.id,
        updated_at: new Date()
    }

    let {data, error} = await supabase.from('profiles').insert(blankProfile)

    if (error) {
        throw error
    }

    return data
}

function getSupabaseProfile(user_id: string) {
    return supabase
        .from<Profile>('profiles')
        .select(`*`)
        .eq('id', user_id);
}