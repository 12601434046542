import * as React from 'react';
import {useEffect, useState} from 'react';
import AppRoutes from "./routes/routes";
import {Session} from "@supabase/supabase-js";
import Login from "./pages/Login/Login";
import {getSession} from "./services/auth/authService";
import {ProfileContext} from './contexts/profile/profileContext';
import {getProfile, Profile} from "./services/profile/profileService";
import {supabase} from "./utils/supabaseClient";


function App() {
    const [session, setSession] = useState<Session | null>(null);
    const [profile, setProfile] = useState<Profile | null>(null);

    useEffect(() => {
        setSession(getSession)

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
    }, [])

    useEffect(() => {
        getProfile(setProfile)
            .catch(error => console.log(error))
    }, [])


    return (
        <>
            {!session ? (<Login/>) : (
                <ProfileContext.Provider value={profile}>
                    <AppRoutes/>
                </ProfileContext.Provider>
            )}
        </>
    );
}


export default App;
