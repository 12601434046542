import {supabase} from "../../utils/supabaseClient";
import {Session} from "@supabase/supabase-js";

export const onAuthStateChange = supabase.auth.onAuthStateChange;

export const userId = supabase.auth.user()?.id

export async function signIn(email: string) {
    const {error} = await supabase.auth.signIn({email})

    if (error) throw error

}

export function getSession(): Session | null {
    return supabase.auth.session()
}
