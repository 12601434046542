import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../components/Button/Button";
import {getWorkouts, Workout} from "../../services/workout/workoutService";
import WorkoutRow from "./components/WorkoutRow/WorkoutRow";
import {RoutePaths} from "../../routes/routes";

function Workouts() {
    const [workouts, setWorkouts] = useState([])

    useEffect(() => {
        getWorkouts(setWorkouts)
    }, [])

    let navigate = useNavigate()

    function goToCreateWorkoutPage() {
        navigate(RoutePaths.NewWorkout, {replace: true})
    }

    return (
        <>
            <h1 className={'font-bold text-xl text-center mb-2'}>Workouts</h1>
            <br/>
            <div className={'w-full text-center'}>
                <Button onClick={goToCreateWorkoutPage}>Start New Workout</Button>
            </div>
            <br/>
            <hr/>
            <br/>
            <h2 className={'font-bold text-gray-500'}>Past Workouts:</h2>
            <ul>
                {workouts.map((workout: Workout) => (
                    <WorkoutRow
                        key={workout.id}
                        workout={workout}/>
                ))}
            </ul>
        </>
    )
}

export default Workouts
