import {ExerciseSet} from "../../../../../../../services/workout/workoutService";
import {useState} from "react";

interface SetProps {
    set: ExerciseSet;
    updateExerciseSets: any;
}

function SetRow({set, updateExerciseSets}: SetProps) {
    const [weight, setWeight] = useState<number|null>(set?.weight)
    const [reps, setReps] = useState<number|null>(set?.reps)
    const [isCompleted, toggleIsCompleted] = useState<boolean>(false)

    function handleChange(property: 'weight' | 'reps' | 'isCompleted', value: string | boolean) {
        let currWeight = weight,
            currReps = reps,
            currIsCompleted = isCompleted;

        switch (property) {
            case "weight":
                setWeight(+value)
                currWeight = +value
                break;
            case 'reps':
                setReps(+value)
                currReps = +value
                break;
            case 'isCompleted':
                toggleIsCompleted(!!value)
                currIsCompleted = !!value
                break;
        }

        let updatedExerciseSet: ExerciseSet = {
            position: set.position,
            weight: currWeight,
            reps: currReps,
            completed: currIsCompleted,
        }

        updateExerciseSets(updatedExerciseSet)
    }

    return (
        <tr>
            <td>
                <p>{set.position}</p>
            </td>
            <td>
                <input
                    type="number"
                    placeholder="Lbs."
                    value={weight ? weight : ''}
                    onChange={(e) => handleChange('weight', e.target.value)}
                    className={'block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0'}/>
            </td>
            <td>
                <input
                    type="number"
                    placeholder="Reps"
                    min={0}
                    value={reps ? reps : ''}
                    onChange={(e) => handleChange('reps', e.target.value)}
                    className={'block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0'}/>
            </td>
            <td>
                <input type="checkbox"
                       checked={isCompleted}
                       onChange={(e) => handleChange('isCompleted', e.target.checked)}
                       className="rounded p-5 bg-gray-100 border-transparent focus:border-transparent focus:bg-gray-200 text-gray-700 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500"/>
            </td>
        </tr>
    )
}

export default SetRow