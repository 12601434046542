import Button from "../../../components/Button/Button";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "../../../routes/routes";
import {createWorkout, Exercise, getWorkout, Workout} from "../../../services/workout/workoutService";
import {userId} from "../../../services/auth/authService";
import WorkoutBuilder from "./components/WorkoutBuilder/WorkoutBuilder";
import ExerciseSection from "./components/Exercise/ExerciseSection";

function View() {
    const { workoutId } = useParams<{workoutId?: string}>();
    const [workout, setWorkout] = useState<Workout|null>(null)
    const [name, setName] = useState<string>('New Workout')
    const [exercises, setExercises] = useState<Exercise[]>([])

    let navigate = useNavigate()

    useEffect(() => {
        if (!workoutId) return;

        getWorkout(+workoutId)
            .then((workout) => {
                setWorkout(workout)
                setName(workout.name)
                setExercises(workout.exercises)
            })
    }, [workoutId])

    function exit() {
        navigate(RoutePaths.Workouts, {replace: true})
    }

    async function saveWorkout() {
        await createWorkout({
            name: name,
            user_id: userId,
            exercises: exercises
        })

        exit()
    }

    function addExercise() {
        setExercises(exercises.concat([{
            position: exercises.length + 1,
            name: '',
            sets: [],
        }]))
    }

    function updateExercise(updatedExercise: Exercise) {
        let updatedExercises = exercises.map((exercise) => {
            return exercise.position === updatedExercise.position ? updatedExercise : exercise;
        })

        setExercises(updatedExercises)
    }

    return (
        <>
            <div className={'flex justify-between items-center text-center mb-5'}>
                <div>
                    <Button onClick={exit}>Cancel</Button>
                </div>
                <div className={'mx-5 px-5 flex-grow'}>
                    <input
                        id="workout-name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder=""
                        className={'block w-full font-bold text-lg text-center px-0.5 py-1 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black'}/>
                </div>
                <div>
                    <Button onClick={saveWorkout}>Save</Button>
                </div>
            </div>
            <br/>
            {exercises.map((exercise) => (<ExerciseSection key={exercise.position} exercise={exercise} updateExercise={updateExercise}/>))}
            <br/>
            <div className={'mt-5 text-center'}>
                <Button onClick={addExercise}>Add Exercise</Button>
            </div>
        </>
    );
}

export default View