import {Outlet, Route, Routes} from "react-router-dom";
import Workouts from "../pages/Workouts/Workouts";
import View from "../pages/Workouts/Workout/View";

export const RoutePaths = {
    Workouts: '/workouts',
    Workout: '/workout',
    NewWorkout: '/workouts/create',
}

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path="*" element={<Workouts/>}/>
                    <Route path={RoutePaths.Workouts} element={<Workouts/>}/>
                    <Route path={RoutePaths.Workout + '/:workoutId'} element={<View/>}/>
                    <Route path={RoutePaths.NewWorkout} element={<View/>}/>
                </Route>
            </Routes>
      </>
  );
}

function Layout() {
    return (
        <div className={'container mx-auto text-gray-700 text-base '}>
            <div className={'flex justify-center'}>
                <div className={'w-full max-w-screen-sm rounded overflow-hidden '}>
                    <div className={'px-6 py-4 min-h-screen'}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppRoutes;