import Button from "../../../../../components/Button/Button";
import SetRow from "./components/SetRow/SetRow";
import {ChangeEvent, useState} from "react";
import {Exercise, ExerciseSet} from "../../../../../services/workout/workoutService";

interface ExerciseSectionProps {
    exercise: Exercise;
    updateExercise: any;
}

function ExerciseSection({exercise, updateExercise}: ExerciseSectionProps) {
    const [exerciseName, setExerciseName] = useState<string>(exercise?.name || '')
    const [exerciseSets, setExerciseSets] = useState<ExerciseSet[]>(exercise && exercise.sets.length > 0 ? exercise.sets : [{
        position: 1,
        weight: null,
        reps: null,
        completed: false,
    }])

    function addSet() {
        let newSets = exerciseSets.concat([{
            position: exerciseSets.length + 1,
            weight: null,
            reps: null,
            completed: false,
        }])

        setExerciseSets(newSets)

        let updatedExercise: Exercise = {
            position: exercise.position,
            name: exerciseName,
            sets: newSets,
        }

        updateExercise(updatedExercise)
    }

    function updateExerciseName(name: string) {
        setExerciseName(name)
        let updatedExercise: Exercise = {
            position: exercise.position,
            name: name,
            sets: exerciseSets,
        }

        updateExercise(updatedExercise)
    }

    function updateExerciseSets(updatedSet: ExerciseSet) {
        let updatedExerciseSets = exerciseSets.map((set) => {
            return set.position === updatedSet.position ? updatedSet : set;
        })

        console.log(updatedExerciseSets)

        setExerciseSets(updatedExerciseSets)

        let updatedExercise: Exercise = {
            position: exercise.position,
            name: exerciseName,
            sets: updatedExerciseSets,
        }

        updateExercise(updatedExercise)
    }

    return (
        <>
            <div className={'text-center'}>
                <input
                    id={'exercise-1'}
                    type="text"
                    value={exerciseName}
                    placeholder="Exercise Name"
                    onChange={(e) => updateExerciseName(e.target.value)}
                    className={'font-bold text-big text-center px-0.5 border-0 border-b-0 border-gray-200 focus:ring-0 focus:border-black'}/>
            </div>
            <table className={'w-full table-auto border-separate text-center'} style={{borderSpacing: '1rem'}}>
                <thead>
                    <tr>
                        <th className={''}>Set</th>
                        <th>Lbs.</th>
                        <th>Reps</th>
                        <th>-</th>
                    </tr>
                </thead>
                <tbody>
                    {exerciseSets.map((set) => (<SetRow key={set.position} set={set} updateExerciseSets={updateExerciseSets}/>))}
                </tbody>
            </table>
            <div className={'my-5 text-center'}>
                <Button onClick={addSet}>Add Set</Button>
            </div>
            <hr className={'my-5'}/>
        </>
    );
}

export default ExerciseSection