import {MouseEvent, useState} from "react";

interface ButtonProps {
    onClick: any;
    children: string;
}

function Button({onClick, children}: ButtonProps) {
    const [loading, setLoading] = useState(false)

    function handleClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setLoading(true)
        onClick()
        setLoading(false)
    }

    return (
        <button
            onClick={(e) => handleClick(e)}
            className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}
            disabled={loading}
        >
            {children}
        </button>
    )
}

export default Button