import {deleteWorkout, Workout} from "../../../../services/workout/workoutService";
import Button from "../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../../routes/routes";


interface WorkoutRowProps {
    workout: Workout;
}

function WorkoutRow({workout}: WorkoutRowProps) {
    let navigate = useNavigate()

    function handleClick() {
        navigate(`${RoutePaths.Workout}/${workout.id}`, {
            replace: true,
        })
    }

    return (
        <li
            className={'my-3 px-5 py-4 rounded cursor-pointer bg-gray-100 flex justify-between items-center'}
        >
            <span className={'mr-5'}>
                { new Date(workout.created_at!).toLocaleDateString()}
            </span>
            <strong className={'flex-grow mr-5'}>
                {workout.name}
            </strong>
            <Button onClick={handleClick}>Do Again</Button>
        </li>
    );
}

export default WorkoutRow