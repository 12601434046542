import {useState} from 'react'
import {signIn} from "../../services/auth/authService";

export default function Login() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const handleLogin = async (email: string) => {
        setLoading(true)

        signIn(email)
            .catch(error => console.log(error))
            .then(() => alert('Check your email for the login link!'))
            .finally(() => setLoading(false))
    }

    return (
        <div className="container mx-auto p-5">
            <div className={'text-center'}>
                <h1 className="text-lg font-bold">BdyBddy</h1>
                <p>Sign in via magic link with your email below</p>
                <div className={'my-5'}>
                    <input
                        type="email"
                        placeholder="Your email"
                        className={'w-full sm:w-96'}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            handleLogin(email)
                        }}
                        className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}
                        disabled={loading}
                    >
                        {loading ? <span>Loading</span> : <span>Send magic link</span>}
                    </button>
                </div>
            </div>
        </div>
    )
}