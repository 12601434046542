import {supabase} from "../../utils/supabaseClient";

export interface ExerciseSet {
    position: number,
    weight: number | null,
    reps: number | null,
    completed: boolean
}

export interface Exercise {
    position: number;
    name: string;
    sets: ExerciseSet[];
}

export interface Workout {
    id?: number;
    name: string;
    exercises: Exercise[];
    user_id?: string;
    created_at?: string;
}

export async function createWorkout(workout: Workout) {
    console.log('saving:', workout)
    const {data, error} = await supabase
        .from('workouts')
        .insert(workout)

    if (error) throw error

    return data;
}

export async function deleteWorkout(workout_id: number) {
    const {data, error} = await supabase
        .from('workouts')
        .delete()
        .eq('id', workout_id)

    if (error) throw error

    return data;
}

export async function getWorkout(workout_id: number): Promise<Workout> {
    let {data, error} = await supabase
        .from('workouts')
        .select('*')
        .eq('id', workout_id)
        .maybeSingle()

    if (error) {
        throw error
    }

    return data
}

export async function getWorkouts(callback: any) {
    let {data, error} = await supabase
        .from<Workout[] | null>('workouts')
        .select('*')

    if (error) {
        throw error
    }

    // use data on callback
    callback(data)
}